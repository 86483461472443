import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { PageLayout } from "./components/PageLayout";
import { HomePageLayout } from "./components/HomePageLayout";
import "./App.css";
import HomePage from "./screens/Home/HomePage";
import Progress from "./screens/Progress1/Progress";
import LoginError from "./screens/Error/LoginError";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import Profile from "./screens/Profile/Profile";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./utils/graph";
import Wall from "./screens/Wall/Wall";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import TermsOfService from "./components/Legal/TermsOfService";
import Credits from "./components/Legal/Credits";
import ClassList from "./screens/ClassList/ClassList";
import Tutorials from "./screens/Tutorials/Tutorials";
import ActivityFinder from "./screens/ActivityFinder/ActivityFinder";
import Resources from "./screens/Resources/Resources";
import Support from "./screens/Support/Support";
import Metrics from "./screens/Metrics";
import { PageLayoutWide } from "./components/PageLayoutWide";
import AdminWall from "./components/AdminWall/AdminWall";
import Cookies from "js-cookie";
import { TourContextProvider } from "./contexts/TourContext";
import { useTranslation } from "react-i18next";
import CookieBar from "./components/CookiesBar";
import AdminAddTeachers from "./components/AdminWall/AddSchoolsTeachers/AdminAddTeachers";
import AdminAddSchools from "./components/AdminWall/AddSchoolsTeachers/AdminAddSchools";
import DemoLogin from "./screens/DemoLogin/DemoLogin";
import { AuthContext } from "./contexts/AuthContext";
import QuickSignUp from "./screens/QuickSignUp";
import RiskPortfolio from "./screens/RiskPortfolio/RiskPortfolio";
import Loading from "./components/Loading";
import SocketListeners from "./listeners/SocketListeners";

export default function App() {
  const authCtx = useContext(AuthContext);
  const microsoftIsAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { i18n } = useTranslation();
  const language = Cookies.get("language");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    const getUserInfo = () => {
      if (microsoftIsAuthenticated && !authCtx.isAuthenticated) {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            callMsGraph(response.accessToken).then((response) => {
              authCtx.authenticate(response);
              console.log("User is Authenticated");
            });
          })
          .catch((error) => {
            console.error("Error during authentication", error);
          });
      }
    };
    getUserInfo();
  }, [microsoftIsAuthenticated, accounts, instance, authCtx]);

  return (
    <TourContextProvider>
      <CookieBar />
      <SocketListeners />
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<PageLayout />}>
              <Route element={<Wall />} path="/wall" />
              <Route element={<ClassList />} path="/class_list" />
              <Route element={<Progress />} path="/progress" />
              <Route element={<Profile />} path="/profile" />
              <Route element={<ActivityFinder />} path="/activity_finder" />
              <Route element={<LoginError />} path="/user_not_found" />
              <Route element={<AdminAddTeachers />} path="/add_teachers" />
              <Route element={<AdminAddSchools />} path="/add_schools" />
              <Route element={<Metrics />} path="/metrics" />
              <Route element={<RiskPortfolio />} path="/risk" />
            </Route>
            <Route element={<PageLayoutWide />}>
              <Route element={<AdminWall />} path="/admin_wall" />
            </Route>
          </Route>
          <Route element={<Loading />} path="/loading" />

          <Route element={<HomePageLayout />}>
            <Route element={<HomePage />} path="/" />
            <Route element={<PrivacyPolicy />} path="/privacy_policy" />
            <Route element={<TermsOfService />} path="/terms_of_service" />
            <Route element={<Credits />} path="/credits" />
          </Route>
          <Route element={<PageLayout />}>
            <Route element={<DemoLogin />} path="/demo" />
            <Route element={<QuickSignUp />} path="/add/:variable" />
            <Route element={<Tutorials />} path="/tutorials" />
            <Route element={<Resources />} path="/resources" />
            <Route element={<Support />} path="/support" />
          </Route>
        </Routes>
      </Router>
    </TourContextProvider>
  );
}
