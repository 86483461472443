import React, { useContext, useState } from "react";
import ProfilePicture from "../profilePicture/ProfilePicture";
import ProfileInfoModal from "./ProfileInfoModal";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomToggle from "../Dropdown/CustomToggle";
import { DoorOpen, Person, Search } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import CustomMenu from "../Dropdown/CustomMenu";
import { SearchContext } from "../../contexts/SearchContext";
import { clearNotifications } from "../../reducers/notificationsSlice";
import { clearSession, setSession } from "../../reducers/sessionSlice";
import { clearProgress } from "../../reducers/progressSlice";
import { clearStudentPermissions } from "../../reducers/studentPermissionsSlice";
import { clearPosts } from "../../reducers/postsSlice";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";
import { MainContext } from "../../contexts/MainContext";

export default function RightPanel(props) {
  const { t } = useTranslation();
  const { query, queriedStudents } = useContext(SearchContext);
  const mainCtx = useContext(MainContext);
  const [showModal, setShowModal] = useState(false);
  const authCtx = useContext(AuthContext);
  const showProfileHandler = (studentId) => {
    mainCtx.setActiveStudent("");
    mainCtx.setActiveStudent(studentId);
    setShowModal(true);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chameleonHandler = (email) => {
    dispatch(clearSession());
    authCtx.disconnectSocket();
    navigate("/");
    dispatch(setSession(email));
    dispatch(clearNotifications());
    dispatch(clearProgress());
    dispatch(clearStudentPermissions());
    dispatch(clearPosts());
  };

  const teacherPermissionsStatus = useSelector(
    (state) => state.teacherPermissions.status
  );

  let filteredStudents = props.teacherPermissions
    ?.slice() // Create a shallow copy of the array
    ?.sort((a, b) => a.last_name.localeCompare(b.last_name))
    .filter((item) => {
      if (query === "") {
        return item;
      } else if (queriedStudents.length !== 0) {
        if (queriedStudents.includes(item.id)) {
          return item;
        }
      } else {
        // Returns by name
        let name = `${item.first_name} ${item.last_name}`;
        return name.toLowerCase().includes(query);
      }
      return false;
    });

  const studentPics = filteredStudents.map((item, i) => (
    <Dropdown key={i}>
      <Dropdown.Toggle as={CustomToggle}>
        <div className="py-2 d-flex text-muted panel-hover px-4">
          <ProfilePicture src={item.profile_pic_src} size={40} />
          <p className="ms-1 my-auto">
            {item.last_name}, {item.first_name}
          </p>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} labeledBy="Student Dropdown">
        <Dropdown.ItemText className="cursor-none fw-bold text-small">
          {item.first_name} {item.last_name}
        </Dropdown.ItemText>

        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => showProfileHandler(item.student_id)}
          eventKey="1">
          <Person
            className={"text-secondary fa-fw"}
            style={{ color: "#38344c" }}
          />
          <span className="text-small ms-2">{t("navbar.Profile")}</span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => {
            if (item.student_id === mainCtx.postsForStudent) {
              mainCtx.setPostsForStudent(null);
            } else {
              mainCtx.setPostsForStudent(item.student_id);
            }
          }}
          eventKey="2">
          <Search className="text-secondary fa-fw" />
          <span className="text-small ms-2">{t("dropdowns.Show Posts")}</span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => chameleonHandler(item.email)}
          eventKey="3">
          <DoorOpen className="text-secondary fa-fw" />
          <span className="text-small ms-2">Chameleon Mode</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ));

  const skeletonContent = Array.from({ length: 10 }, (_, index) => (
    <div
      key={index}
      className="d-flex align-items-center py-2 text-muted panel-hover px-4">
      {/* Skeleton Circle */}
      <Skeleton
        circle={true}
        width={40}
        height={40}
        style={{ marginRight: "10px" }}
        baseColor="white"
      />

      {/* Skeleton Text */}
      <Skeleton width={200} />
    </div>
  ));

  return (
    <div>
      <p className="text-muted fw-bold mb-3 px-4 ">{t("navbar.Students")}</p>
      <div className="vh-100 overflow-auto">
        <div>
          {teacherPermissionsStatus === "succeeded"
            ? studentPics
            : skeletonContent}
        </div>
        {showModal && (
          <ProfileInfoModal
            show={showModal}
            onHide={() => setShowModal(false)}
          />
        )}
      </div>
    </div>
  );
}
