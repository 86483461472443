import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const fetchAllProgressTeacher = createAsyncThunk(
  "progressTeacher/fetchAllProgressTeacher",
  async (obj) => {
    const settings = {
      body: JSON.stringify(obj),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(`${baseUrl}/get_all_progress`, settings);
    const data = await response.json();
    return data;
  }
);

export const fetchProgressTeacher = createAsyncThunk(
  "progressTeacher/fetchProgressTeacher",
  async (obj) => {
    const response = await fetch(`${baseUrl}/get_progress/${obj.userId}`);
    const progress = await response.json();
    return progress;
  }
);

export const bulkAddGoals = createAsyncThunk(
  "progressTeacher/bulkAddGoals",
  async (object) => {
    try {
      const url = `${baseUrl}/bulk_add_goals`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const changeProgress = createAsyncThunk(
  "progressTeacher/changeProgress",
  async (object) => {
    try {
      const url = `${baseUrl}/change_progress`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  progressTeacher: [],
  status: "idle",
  error: null,
};

const progressTeacherSlice = createSlice({
  name: "progressTeacher",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchProgressTeacher.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchProgressTeacher.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.progressTeacher = state.progressTeacher.concat(action.payload);
      })
      .addCase(fetchProgressTeacher.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllProgressTeacher.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAllProgressTeacher.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.progressTeacher = state.progressTeacher.concat(action.payload);
      })
      .addCase(fetchAllProgressTeacher.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(changeProgress.fulfilled, (state, action) => {
        state.status = "succeeded";

        // Find the index of the existing entry
        const index = state.progressTeacher.findIndex(
          (item) => item.studentId === action.payload.studentId
        );

        if (index !== -1) {
          // Replace the existing entry
          state.progressTeacher[index] = action.payload;
        } else {
          // If the entry does not exist, add it
          state.progressTeacher.push(action.payload);
        }
      })
      .addCase(bulkAddGoals.fulfilled, (state, action) => {
        const studentIdArray = action.payload.students;
        // In remainingGoals,
        for (let studentId of studentIdArray) {
          let studentIndex = state.progressTeacher.findIndex(
            (item) => item.studentId === studentId
          );
          let progressIndex = state.progressTeacher[
            studentIndex
          ].data.findIndex((item) => item.strand === action.payload.strand);
          state.progressTeacher[studentIndex].data[
            progressIndex
          ].in_progress.push(action.payload.cefr_custom_id);
          state.progressTeacher[studentIndex].data[
            progressIndex
          ].remaining_goals = state.progressTeacher[studentIndex].data[
            progressIndex
          ].remaining_goals.filter(
            (item) => item.id !== action.payload.cefr_custom_id
          );
          // Remove goal from Completed lists if this was Completed -> In Progress
          try {
            state.progressTeacher[studentIndex].data[
              progressIndex
            ].goals_completed_id = state.progressTeacher[studentIndex].data[
              progressIndex
            ].goals_completed_id.filter(
              (id) => id !== action.payload.cefr_custom_id
            );
          } catch {
            return false;
          }
        }
      });
  },
});

export default progressTeacherSlice.reducer;

export const selectAllProgressTeacher = (state, studentId) => {
  let progress = state.progressTeacher.progressTeacher;
  return progress;
};

export const selectProgressForStudent = (studentId) => (state) => {
  const progress = selectAllProgressTeacher(state);
  const filteredProgress = progress.filter(
    (item) => item.studentId === studentId
  );
  return filteredProgress[filteredProgress.length - 1]?.data || null; // Return the last item or null if none exists
};
