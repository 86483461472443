import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../reducers/notificationsSlice";
import { AuthContext } from "../contexts/AuthContext";
import { addNewReply } from "../reducers/postsSlice";

const SocketListeners = () => {
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const socket = authCtx.socket;

  useEffect(() => {
    if (socket) {
      console.log("Socket connected:", socket.connected);
      socket.on("notification_listener", (data) => {
        console.log("Notification received:", data);
        dispatch(addNotification(data)); // Dispatch action to add the new notification
      });
      socket.on("add_reply_listener", (data) => {
        console.log("Reply added:", data);
        dispatch(addNewReply.fulfilled(data)); // Dispatch action to add the new notification
      });

      return () => {
        socket.off("notification_listener");
      };
    }
  }, [dispatch, socket]);

  return null; // This component doesn't need to render anything
};

export default SocketListeners;
