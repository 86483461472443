import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const fetchAdminSchoolsList = createAsyncThunk(
  "adminSchoolsList/fetchSchoolsList",
  async (district_id, { rejectWithValue }) => {
    try {
      const response = await fetch(`${baseUrl}/get_schools/${district_id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const schoolsList = await response.json();
      return schoolsList;
    } catch (error) {
      return rejectWithValue(error.message); // Properly handle errors
    }
  }
);

export const addSchool = createAsyncThunk(
  "adminSchoolsList/addSchool",
  async (obj) => {
    try {
      const response = await fetch(`${baseUrl}/add_school`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const editSchool = createAsyncThunk(
  "adminSchoolsList/editSchool",
  async (obj) => {
    try {
      const response = await fetch(`${baseUrl}/edit_school`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  adminSchoolsList: [],
  status: "idle",
  error: null,
};

const adminSchoolsListSlice = createSlice({
  name: "adminSchoolsList",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAdminSchoolsList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAdminSchoolsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminSchoolsList = action.payload;
      })
      .addCase(fetchAdminSchoolsList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addSchool.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addSchool.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminSchoolsList = state.adminSchoolsList.concat(action.payload);
      })
      .addCase(addSchool.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(editSchool.fulfilled, (state, action) => {
        const data = state.adminSchoolsList;
        let schoolIndex = data.findIndex(
          (item) => item.school_id === action.payload.school_id
        );
        const school = data[schoolIndex];
        const updatedSchool = {
          ...school,
          school_name: action.payload.school_name,
          level: action.payload.level,
          teacher_email: action.payload.teacher_email,
        };

        // create a new state using object spread or assign
        return {
          ...state,
          adminSchoolsList: [
            ...data.slice(0, schoolIndex),
            updatedSchool,
            ...data.slice(schoolIndex + 1),
          ],
        };
      });
  },
});

export default adminSchoolsListSlice.reducer;

export const selectAdminSchoolsList = (state) =>
  state.adminSchoolsList.adminSchoolsList;
