import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import StudentWall from "../../components/StudentWall/StudentWall";
import TeacherWall from "../../components/TeacherWall/TeacherWall";
import "react-loading-skeleton/dist/skeleton.css";
import { Navigate } from "react-router";
import DeveloperWall from "../../components/DeveloperWall/DeveloperWall";
import { MainContext } from "../../contexts/MainContext";
import SuccessMessage from "../../components/SuccessMessage";

export default function Wall() {
  const user = useSelector(selectUser);
  const mainCtx = useContext(MainContext);

  return (
    <>
      {mainCtx.showSuccessMessage && (
        <SuccessMessage onClose={() => mainCtx.setShowSuccessMessage(false)} />
      )}
      {user?.role === "Teacher" && <TeacherWall user={user} />}
      {user?.role === "Student" && <StudentWall user={user} />}
      {user?.role === "Administrator" && <Navigate to={"/admin_wall"} />}
      {user?.role === "Developer" && <DeveloperWall />}
    </>
  );
}
