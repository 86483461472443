import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/index";
import reportWebVitals from "./reportWebVitals";
// import { StrictMode } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "./i18n";
import "./web.config";
import AuthProvider from "./contexts/AuthContext";
import MainProvider from "./contexts/MainContext";

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<div className="p-3">Loading/Chargement...</div>}>
    <MainProvider>
      <AuthProvider>
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <App />
          </Provider>
        </MsalProvider>
      </AuthProvider>
    </MainProvider>
  </Suspense>
);

reportWebVitals();
