import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (user_id) => {
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${baseUrl}/get_notifications/${user_id}`,
      settings
    );
    const notifications = await response.json();
    return notifications;
  }
);

export const markNotificationRead = createAsyncThunk(
  "notifications/markNotificationRead",
  async (notification_id) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${baseUrl}/mark_notification_read/${notification_id}`,
      settings
    );
    const notifications = await response.json();
    return notifications;
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",
  async (notification_id) => {
    const settings = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${baseUrl}/delete_notification/${notification_id}`,
      settings
    );
    const notifications = await response.json();
    return notifications;
  }
);

const initialState = {
  notifications: [],
  status: "idle",
  error: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    clearNotifications: () => {
      return { ...initialState };
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading"; // Set status to 'loading' when the request starts
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "succeeded"; // Set status to 'succeeded' when the request is successful
        state.notifications.push(...action.payload);
        // Optionally sort by date, etc.
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = "failed"; // Set status to 'failed' if the request fails
        state.error = action.error.message;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter(
          (notification) => notification.id !== action.payload.id
        );
      })
      .addCase(markNotificationRead.fulfilled, (state, action) => {
        const index = state.notifications.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.notifications[index].read = true;
        }
      });
  },
});

export default notificationsSlice.reducer;

export const { clearNotifications, addNotification } =
  notificationsSlice.actions;

export const selectAllNotifications = (state) =>
  state.notifications.notifications;
