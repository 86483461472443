import React, { useState } from "react";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookie } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/esm/Button";

export function CookieBar() {
  const [isCookieSet, setCookie] = useState(
    Cookies.get("cookieConsent") === "true"
  );

  // Function to handle accepting cookies
  const handleAcceptCookies = () => {
    Cookies.set("cookieConsent", true, { expires: 365 }); // Set cookie for 1 year
    setCookie(true);
  };

  // Function to handle rejecting cookies
  const handleRejectCookies = () => {
    Cookies.remove("cookieConsent");
    setCookie(false);
  };

  return (
    !isCookieSet && (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
        }}>
        <div
          className="p-2 d-flex flex-row align-items-center justify-content-center bg-black"
          style={{ opacity: 0.8 }}>
          <FontAwesomeIcon icon={faCookie} className="text-white pe-3 fa-xl" />
          <p className="text-center m-0 text-white text-small">
            By using this website, you agree to the use of cookies.
          </p>

          <Button
            className="btn btn-sm btn-outline-light ms-5"
            style={{ color: "white" }} // Set button text color to white
            onClick={handleAcceptCookies}>
            Accept
          </Button>
          <Button
            className="btn btn-sm btn-outline-light ms-2"
            style={{ color: "white" }} // Set button text color to white
            onClick={handleRejectCookies}>
            Reject
          </Button>
        </div>
      </div>
    )
  );
}

export default CookieBar;
