import React, { useMemo, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import {
  fetchAllProgressTeacher,
  selectAllProgressTeacher,
} from "../../reducers/progressTeacherSlice";
import AddStudentModal from "../AddStudent/AddStudentModal";
import { useTranslation } from "react-i18next";
import toTitleCase from "../../utils/toTitleCase";
import ProfileInfoModal from "../TeacherWall/ProfileInfoModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  selectGroupNames,
  selectGroupings,
} from "../../reducers/groupingsSlice";
import { PersonAdd } from "react-bootstrap-icons";
import alphabetize from "../../utils/alphabetize";
import { SearchContext } from "../../contexts/SearchContext";
import ClassListFilter from "./ClassListFilter";
import { appLevelsReverse } from "../../utils/levels";
import { selectDistrictGoals, selectUser } from "../../reducers/sessionSlice";
import { MainContext } from "../../contexts/MainContext";

export default function ClassListTable() {
  const { t, i18n } = useTranslation();
  const [addStudentModal, setAddStudentModal] = useState(false);
  const { query, queriedStudents } = useContext(SearchContext);
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const progressStatus = useSelector((state) => state.progressTeacher.status);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [groupFilter, setGroupFilter] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [progressByGoal, setProgressByGoal] = useState({});
  const districtGoals = useSelector(selectDistrictGoals);
  const user = useSelector(selectUser);
  const groupNames = useSelector(selectGroupNames);
  const groupings = useSelector(selectGroupings);
  const progressObj = useSelector(selectAllProgressTeacher);
  const mainCtx = useContext(MainContext);

  const isEmpty = (obj) => {
    return Object.entries(obj).length === 0;
  };

  const renderSkeletonRow = () => {
    return (
      <tr>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
      </tr>
    );
  };

  const strands = useMemo(() => {
    return (
      progressObj[0]?.data.map((item) => {
        return item.strand;
      }) || []
    );
  }, [progressObj]);

  const [listObj, setListObj] = useState([]);

  useMemo(() => {
    let allStudentIds = teacherPermissions?.map((student) => {
      return student?.student_id;
    });

    let fetchedStudentIds = progressObj?.map((student) => {
      return student?.studentId;
    });

    let studentIds = allStudentIds.filter(
      (val) => !fetchedStudentIds.includes(val)
    );

    if (progressObj.length < allStudentIds.length) {
      dispatch(fetchAllProgressTeacher({ studentIds: studentIds }));
    }
  }, [dispatch, progressObj, teacherPermissions]);

  const getGroupName = useMemo(() => {
    return (groupNames, grouping_id) => {
      return groupNames.find((item) => item.id === grouping_id)?.name;
    };
  }, []);

  useEffect(() => {
    let list = progressObj.map((obj) => {
      let student = teacherPermissions?.find(
        (item) => item?.student_id === obj.studentId
      );
      let group_id = groupings?.find(
        (item) => item?.student_id === obj.studentId
      )?.grouping_id;
      let permObj = { ...obj };
      permObj["first_name"] = student?.first_name || "";
      permObj["last_name"] = student?.last_name || "";
      permObj["group_name"] = getGroupName(groupNames, group_id) || "";
      return { ...permObj };
    });

    list = list.filter((item) => {
      if (query === "" || query === null) {
        return item;
      } else if (queriedStudents.length !== 0) {
        if (queriedStudents.includes(item.studentId)) {
          return item;
        }
      } else {
        let name = `${item.first_name} ${item.last_name}`;
        return name.toLowerCase().includes(query);
      }
      return false;
    });

    list = list.filter((item) => {
      if (selectedGroup.length === 0) {
        return item;
      } else if (groupFilter.length === 0) {
        return false;
      } else if (groupFilter.length !== 0) {
        if (groupFilter.includes(item.studentId)) {
          return item;
        }
      } else {
        let name = `${item.first_name} ${item.last_name}`;
        return name.toLowerCase().includes(query);
      }
      return false;
    });

    const alphabetizedList = list.sort(alphabetize);
    setListObj(alphabetizedList);
  }, [
    progressObj,
    setListObj,
    groupings,
    groupNames,
    query,
    queriedStudents,
    groupFilter,
    selectedGroup,
    teacherPermissions,
    getGroupName,
  ]);

  const tableBody = useMemo(() => {
    const handleClick = (id) => {
      mainCtx.setActiveStudent(id);
      setShowModal(true);
    };
    return progressStatus === "succeeded" || teacherPermissions.length === 0
      ? listObj?.map((obj, i) => {
          let grade = isEmpty(progressByGoal)
            ? 0
            : progressByGoal.student_data[obj.studentId]?.["grade"];
          let rowStyle = {};
          if (!isEmpty(progressByGoal)) {
            if (grade === "" || grade === 1) {
              rowStyle.backgroundColor = "#ffe5e5"; // Very light red
            } else if (grade === 2 || grade === 3) {
              rowStyle.backgroundColor = "#fff3db"; // Very light yellow
            } else if (grade === 4) {
              rowStyle.backgroundColor = "#e6f3ea"; // Very light green
            }
          }
          let tableRow = (
            <tr key={i} className="text-small">
              <th style={rowStyle} scope="row" className="col-1">
                {i + 1}
              </th>
              <td style={rowStyle}>
                <u
                  className="cursor-pointer"
                  onClick={() => handleClick(obj?.studentId)}>
                  {obj?.last_name}, {obj?.first_name}
                </u>
              </td>
              <td style={rowStyle}>{obj?.group_name}</td>
              {isEmpty(progressByGoal) ? (
                obj?.data?.map((strand, i) => (
                  <td style={rowStyle} key={i}>
                    {strand?.overall_cefr_grade}
                  </td>
                ))
              ) : (
                <>
                  <td style={rowStyle} key={obj?.studentId}>
                    {grade}
                  </td>
                  <td style={rowStyle} key={`${obj?.studentId}_1`}>
                    {progressByGoal?.student_data[obj.studentId]?.["status"]}
                  </td>
                </>
              )}
            </tr>
          );
          return tableRow;
        })
      : Array.from({ length: 15 }).map((_, index) => (
          <React.Fragment key={index}>{renderSkeletonRow()}</React.Fragment>
        ));
  }, [progressStatus, listObj, teacherPermissions, progressByGoal]);

  useEffect(() => {
    let compA, compB, listToSort;
    if (sortConfig !== null) {
      listToSort = [...listObj];

      const sortedList = listToSort.sort((a, b) => {
        if (sortConfig?.key === "group_name") {
          compA = a.group_name;
          compB = b.group_name;
        } else {
          compA = a[sortConfig?.key]?.grade;
          compB = b[sortConfig?.key]?.grade;
        }
        return (
          compA.localeCompare(compB) *
          (sortConfig.direction === "ascending" ? 1 : -1)
        );
      });
      setListObj(sortedList);
    }
  }, [sortConfig, listObj]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig?.key === key && sortConfig?.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="mx-lg-5">
      {addStudentModal && (
        <AddStudentModal
          show={addStudentModal}
          handleClose={() => setAddStudentModal(false)}
          district_levels={user?.district_levels}
          school={user?.school}
          school_id={user?.school_id}
          primary_teacher_id={user?.primary_teacher_id}
          district_goals={user?.district_goals}
          district_id={user?.district_id}
        />
      )}
      <h4 className="text-center p-2 text-uppercase">
        {t(`classList.Class List`)}
      </h4>

      <div
        className="container-fluid p-0 d-flex flex-column"
        id="container"
        datenow="">
        <ClassListFilter
          setGroupFilter={setGroupFilter}
          setProgressByGoal={setProgressByGoal}
          progressByGoal={progressByGoal}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />
        <div className="flex-grow-1 overflow-auto">
          <table className="table m-0 p-2 bg-white">
            <thead>
              <tr className="table-success">
                <th scope="col" className="col-1"></th>
                <th
                  className="user-select-none text-uppercase fw-normal text-small col-2"
                  scope="col">
                  {t(`addStudent.Name`).toUpperCase()}
                  <u
                    className="cursor-pointer"
                    onClick={() => setAddStudentModal(true)}>
                    <PersonAdd
                      className={"me-2 fa-fw"}
                      style={{ color: "#38344c" }}
                    />
                  </u>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer user-select-none text-uppercase fw-normal text-small col-2"
                  onClick={() => {
                    requestSort("group_name");
                  }}>
                  <span className="me-1">
                    {sortConfig?.key === "group_name" &&
                    sortConfig?.direction === "ascending"
                      ? "▲"
                      : "▼"}
                  </span>
                  {t(`addStudent.Group`).toUpperCase()}
                </th>
                {progressStatus === "succeeded" &&
                  isEmpty(progressByGoal) &&
                  strands?.map((item, i) => (
                    <th
                      key={i}
                      scope="col"
                      className="cursor-pointer user-select-none text-uppercase fw-normal text-small col-1">
                      {t(`addStudent.${toTitleCase(item)}`)}
                    </th>
                  ))}
                {!isEmpty(progressByGoal) && (
                  <>
                    <th
                      scope="col"
                      className="user-select-none text-uppercase fw-normal text-small col-2">
                      <span className="me-1">
                        {appLevelsReverse[progressByGoal["level"]]}:
                      </span>
                      <span>
                        {i18n.language === "en"
                          ? districtGoals.find(
                              (item) =>
                                item.id === progressByGoal["cefr_custom_id"]
                            ).statement
                          : districtGoals.find(
                              (item) =>
                                item.id === progressByGoal["cefr_custom_id"]
                            ).statement_fr}
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="user-select-none text-uppercase fw-normal text-small col-1">
                      {t(`classList.Status`)}
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </div>

      {showModal && (
        <ProfileInfoModal show={showModal} onHide={() => setShowModal(false)} />
      )}
    </div>
  );
}
